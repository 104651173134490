@import '../node_modules/@boldreports/javascript-reporting-controls/Content/bootstrap4/bold.reports.all.min.css';
@import '../node_modules/@boldreports/javascript-reporting-controls/Content/bootstrap4/bold.reportdesigner.min.css';
@import '../node_modules/@syncfusion/ej2-base/styles/fluent2.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/fluent2.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/fluent2.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/fluent2.css';  
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/fluent2.css";
@import '../node_modules/@syncfusion/ej2-inputs/styles/fluent2.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/fluent2.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/fluent2.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/fluent2.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/fluent2.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/fluent2.css";
@import "../node_modules/@syncfusion/ej2-querybuilder/styles/fluent2.css";
@import '../node_modules/@syncfusion/ej2-notifications/styles/fluent2.css';
@import "../node_modules/@syncfusion/ej2-icons/styles/fluent2.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/fluent2.css";

@import "../node_modules/@syncfusion/ej2-react-popups/styles/fluent2.css";
/* @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-querybuilder/styles/material.css"; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.e-vscroll .e-box{
  padding: 0 2px !important;
}
.e-vscroll:not(.e-scroll-device) {
  padding: 0 2px !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footerf {
  /* margin-top: rem(120px); */
  /* display: flex; */
  /* justify-content: end; */
  /* bottom: 0; */
  /* right:2; */
  /* justify-content: space-evenly; */
  /* position: sticky; */
  border: 0.3px solid;
  /* width: 100%; */
  background-color: #fff;
  /* padding: 8px; */
  /* z-index: 999; */
}

.innerf {
  display: flex;
  /* margin-left: 15%; */
  /* justify-content: end; */
  /* align-items: center; */
  /* padding-top: var(--mantine-spacing-xl); */
  /* padding-bottom: var(--mantine-spacing-xl); */

  @media (max-width: $mantine-breakpoint-xs) {
    flex-direction: column;
  }
}

.linksf {
  @media (max-width: $mantine-breakpoint-xs) {
    margin-top: var(--mantine-spacing-md);
  }
}

.contextmenu {
  .menu:hover{
    background-color: #282c34;
    color: azure;
  }
}
.context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius:5;
  opacity: 0;
  /* transform: scale(0.9); */
  transition: opacity 0.3s ease-in, transform 0.3s ease-out;
}

.context-menu.visible {
  opacity: 1;
  transform: scale(1);
}

.context-menu-item {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.context-menu-item:last-child {
  border-bottom: none;
}

.context-menu-item:hover {
  background-color: #282c34;
  color: azure;
}

.whitespace{
  white-space: initial;
  /* text-align: right; */
}
.whitespace .left{
  text-align: left;
}
.whitespace .right{
  text-align: right;
}
.boxshadow{
  -webkit-box-shadow: 0 0 12px 2px rgba(0,0,0,0.3);
-moz-box-shadow: 0 0 12px 2px rgba(0,0,0,0.3);
box-shadow: 0 0 12px 2px rgba(0,0,0,0.3);
}
.boxshadow-btn{
  -webkit-box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
}
/* button[aria-label="Show/Hide search"] {
  display: none !important;
} */

/* QueryBuilderComponent dropdown background color */
.e-treeview .e-list-item.e-active > .e-fullrow{
  background: rgba(235, 235, 235, 0.4) !important;
  color: black !important;
}

.e-list-parent .e-ul > .e-list-item :hover{
  background: rgba(235, 235, 235, 0.4) !important;
  color: black !important;
}

/* QueryBuilderComponent dropdown width */
/* .e-filter-input { 
  width: 250px !important;
} */
/* QueryBuilderComponent combobox and dataGrid */
/* .e-rule-value{
  width: 350px !important;
} */

/* .e-icons.e-export {
  font-size: 24px !important;
  color: #0078d4 !important;
} */

/* .main-menu .e-menu-item .e-caret {
  display: none; Hide the caret icon in the main menu
} */
/* .e-menu-wrapper .e-ul .e-menu-item .e-caret{
  line-height: 14px !important;
}

.e-vscroll:not(.e-scroll-device) {
  padding: 0 !important;
} */
.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav{
  /* height: 28px !important; */
  z-index: 1000;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-vscroll-bar, .e-menu-container .e-menu-vscroll.e-vscroll .e-vscroll-bar {
  padding: 30px 0 !important;
}
/* .e-tab .e-tab-header {
  position: sticky !important;
} */
/* Ensure the main container does not interfere */
/* Ensure full height for the app shell */
.mantine-Appshell-main {
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevents unwanted scrolling */
  display: flex;
  flex-direction: column;
}

/* Ensure e-tab takes full height */
.e-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Sticky header */
.e-tab .e-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Responsive, fixed height content */
.e-tab > .e-content {
  height: calc(100vh - 80px) !important; /* Adjust height dynamically (subtract header height) */
  max-height: 82vh; /* Maximum height for large screens */
  min-height: 200px; /* Minimum height for small screens */
  overflow: auto; /* Enable scrolling if needed */
  padding: 10px;
  background: #f9f9f9;
  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}
.e-tab .e-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}
.e-groupcaptionrow{
  background-color: "#f4f4f4";
  font-weight: "bold";
}
